import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import ContactForm from '../components/sections/contact-form'
import HeroBig from "../components/sections/hero-big"
import Pagination from '../components/common/Pagination'
import PostCard from '../components/common/PostCard'

const pageNews = ({ data, location, pageContext }) => {
  const page = data.ghostPage
  const ALL_POSTS = data.newsPosts.edges
  const cta_image = data.heroImage.childImageSharp.gatsbyImageData

  return (
    <>
      <MetaData
        data={data}
        location={location}
        type="website"
      />
      <Helmet>
        <style type="text/css">{`${page.codeinjection_styles}`}</style>
      </Helmet>
      <Layout>
        <article className="page-news">
          <HeroBig
            headerTitle={"Our News"}
            headerSubtitle={"We Build. We Create. We Share."}
            ctaImage={cta_image}
            ctaTitle={"Creativity is to discover a question that has never been asked."}
            ctaText={"How well we communicate is <br>determined not by how well we say <br>things, but how well we are understood."}
          />
          <section className="feed-layout__two dec-section--line-vertical">
            <div className="container">
              <div className="row">
                {ALL_POSTS.map(({ node }) => (
                  <PostCard key={node.id} post={node} />
                ))}
              </div>
              <div className="row">
                <div className="col">
                  <Pagination pageContext={pageContext} />
                </div>
              </div>
            </div>
          </section>
          <ContactForm />
        </article>
      </Layout>
    </>
  )
}

export const allPostsQuery2 = graphql`query ($skip: Int!, $limit: Int!) {
  newsPosts: allGhostPost(
    limit: $limit
    skip: $skip
    sort: {order: DESC, fields: published_at}
    filter: {tags: {elemMatch: {slug: {eq: "news"}}}}
  ) {
    edges {
      node {
        ...GhostPostFields
      }
    }
  }
  ghostPage: ghostPage(tags: {elemMatch: {name: {eq: "#page-news"}}}) {
    ...GhostPageFields
  }
  heroImage: file(relativePath: {eq: "hero/hero-news.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 644, quality: 80, layout: CONSTRAINED)
    }
  }
}
`

export default pageNews
